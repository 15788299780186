(function() {
    // Elements
    var containers = [];

    // Classes
    var classActive = 'active';
    var classHiding = 'hiding';
    var classShow = 'show';

    // Debounce function
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    // Initialise feature text tab
    function initFeatureTextTab(container) {
        var buttons = Array.prototype.slice.call(container.querySelectorAll('.feature-text-tab-button'));
        var tabs = Array.prototype.slice.call(container.querySelectorAll('.feature-text-tab'));

        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function(e) {
                e.preventDefault();

                // Update buttons active state
                for (var j = 0; j < buttons.length; j++) {
                    if (buttons[j] === e.target) {
                        buttons[j].classList.add(classActive);
                    } else {
                        buttons[j].classList.remove(classActive);
                    }
                }

                // Hide/show tab
                var current;

                for (var j = 0; j < tabs.length; j++) {
                    if (tabs[j].classList.contains(classShow)) {
                        current = tabs[j];
                        break;
                    }
                }

                current.classList.add(classHiding);

                setTimeout(function() {
                    current.classList.remove(classHiding);
                    current.classList.remove(classShow);

                    for (var j = 0; j < tabs.length; j++) {
                        if (tabs[j].id === e.target.href.split('#')[1]) {
                            tabs[j].classList.add(classShow);
                        }
                    }
                }, 250);
            });
        }
    }

    function setTextContainerHeight(container) {
        var textContainer = container.querySelector('.feature-text-tabs-text-container');

        if (textContainer) {
            textContainer.removeAttribute('style');

            var textTabs = Array.prototype.slice.call(textContainer.querySelectorAll('.feature-text-tab'));
            var maxHeight = 0;

            for (var i = 0; i < textTabs.length; i++) {
                var hasShowClass = textTabs[i].classList.contains(classShow);

                textTabs[i].classList.add(classShow);

                if (maxHeight < textTabs[i].offsetHeight) {
                    maxHeight = textTabs[i].offsetHeight;
                }

                if (!hasShowClass) {
                    textTabs[i].classList.remove(classShow);
                }
            }

            textContainer.style.height = maxHeight + 'px';
        }
    }

    function setTextContainerHeights() {
        for (var i = 0; i < containers.length; i++) {
            setTextContainerHeight(containers[i]);
        }
    }

    // Initialise feature text tabs
    function init() {
        containers = Array.prototype.slice.call(document.querySelectorAll('.feature-text-tabs'));

        for (var i = 0; i < containers.length; i++) {
            initFeatureTextTab(containers[i]);
        }

        setTextContainerHeights();

        var debouncedResizeHandler = debounce(function() {
            setTextContainerHeights();
        }, 250);

        window.addEventListener('resize', debouncedResizeHandler);
    }

    // Perform init
    init();
})();